"use client";
// packages
import React, { useState, useEffect } from "react";

// components
import { Drawer, DrawerContent } from "@/shared/components/ui/drawer";
import { Button } from "@/shared/components/ui/Button";
import { Label } from "@/shared/components/ui/label";
import { Switch } from "@/shared/components/ui/switch";
import BreakpointsBadge from "./BreakpointsBadge";
import { ErrorSelect } from "./ErrorSelect";
import CreditCardTestSelect from "./CreditCardTestSelect";

// utils
import { PublicConfig } from "@/shared/PublicConfig";

// hooks
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

// media
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { Select } from "../ui/Select";
import { QUOTE_VERSIONS } from "@/shared/utils/constants";
import { useRouter } from "next/navigation";
import CheckoutButton from "./CheckoutButton";

// type SpotColorTheme = "system" | "light" | "dark";
function SpotDevTools() {
    const [open, setOpen] = useState(true);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [colorTheme, setColorTheme] = useState("light"); // Not using boolean here to leave the door open for more theming possibilities
    const router = useRouter();
    const { appState } = useAppLayerContext();
    const { formID } = appState;

    const handleClearQuote = (redirect = false) => {
        // clear local storage
        localStorage.clear();

        // remove all cookies
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });

        // remove all query params
        window.history.replaceState({}, document.title, window.location.pathname);

        // clear local storage again in the event that the app automatically adds quoteid back to local storage
        setTimeout(() => {
            localStorage.clear();
            if (redirect) {
                window.location.href = "/";
            }
        }, 700);
    };

    useEffect(() => {
        document.documentElement.setAttribute("data-theme", colorTheme);
    }, [colorTheme]);

    const handleQuoteToggle = (version: string) => {
        const basePath = window.location.origin;
        const targetPath = `${basePath}/${version}`;

        handleClearQuote();
        router.push(targetPath);
    };

    return (
        PublicConfig.DEV_ENV &&
        open && (
            <>
                <div className="dev-stripe fixed inset-x-0 top-0 z-[999] flex h-1 justify-center px-1">
                    <button className="dev-badge" onClick={() => setOpenDrawer(!openDrawer)}>
                        Dev
                    </button>
                </div>
                <Drawer open={openDrawer} onOpenChange={setOpenDrawer} direction="right">
                    <DrawerContent variant="right">
                        <div className="h-full flex-1 bg-background-primary p-4">
                            <div className="flex h-full max-w-md flex-col gap-4">
                                <BreakpointsBadge className="ml-auto" />
                                <div className="flex gap-4">
                                    <div className="flex-1">
                                        <Button className="w-full" onClick={() => handleClearQuote(true)} startDecorator={<FontAwesomeIcon icon={faTrashCan} size="lg" />}>
                                            Clear Quote
                                        </Button>
                                    </div>
                                    {formID === PublicConfig.PTZ_US.FORM_ID && (
                                        <div className="flex-1">
                                            <CheckoutButton />
                                        </div>
                                    )}
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <Switch
                                        aria-labelledby="dark-mode-label"
                                        id="dark-mode"
                                        checked={colorTheme === "dark"}
                                        onCheckedChange={() => setColorTheme(colorTheme === "dark" ? "light" : "dark")}
                                    />
                                    <Label id="dark-mode-label" htmlFor="dark-mode" className="mb-0 bg-background-transparent text-sm">
                                        Dark Mode
                                    </Label>
                                </div>
                                <div className="w-auto">
                                    <Select
                                        value={undefined}
                                        placeholder="Toggle Quote Form"
                                        options={QUOTE_VERSIONS}
                                        onValueChange={version => handleQuoteToggle(version)}
                                    ></Select>
                                </div>
                                <div className="w-auto">
                                    <ErrorSelect />
                                </div>
                                <div className="w-auto">
                                    <CreditCardTestSelect />
                                </div>
                                <div className="mt-auto w-auto">
                                    <Button variant="outline" onClick={() => setOpen(false)}>
                                        Hide Tools (until refresh)
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DrawerContent>
                </Drawer>
            </>
        )
    );
}

SpotDevTools.displayName = "SpotDevTools";

export { SpotDevTools };
