export default class StripeTest {
    static CARDS = {
        // Valid test cards:
        VISA: "4242424242424242",
        VISA_DEBIT: "4000056655665556",
        MASTERCARD: "5555555555554444",
        MASTERCARD_DEBIT: "5200828282828210",
        AMEX: "378282246310005",
        DISCOVER: "6011111111111117",
        JCB: "3566002020360505",
        DINERS_CLUB: "30569309025904",
        UNIONPAY: "6200000000000005",

        // Error test cards:
        GENERIC_DECLINE: "4000000000000002",
        INSUFFICIENT_FUNDS: "4000000000009995",
        LOST_CARD: "4000000000009987",
        STOLEN_CARD: "4000000000009979",
        EXPIRED_CARD: "4000000000000069",
        INCORRECT_CVC: "4000000000000127",
        POSTAL_CODE_FAIL: "4000000000000036"
    };

    static get EXPIRY() {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        return {
            VALID: `${currentMonth}/${(currentYear + 1).toString().slice(-2)}`,
            INVALID: `${currentMonth}/${(currentYear - 1).toString().slice(-2)}`
        };
    }

    static STRINGS = {
        ERRORS: {
            CARD_INCOMPLETE: "Your card number is incomplete.",
            CARD_INVALID: "Your card number is invalid.",
            EXPIRY_INCOMPLETE: "Your card's expiration date is incomplete.",
            EXPIRY_PAST: "Your card's expiration date is in the past.",
            EXPIRY_YEAR_PAST: "Your card's expiration year is in the past.",
            CVC_INCOMPLETE: "Your card's security code is incomplete."
        }
    };
}
